import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Header from '../components/Header'
import Footer from '../components/Footer'
import InterviewsList from '../components/InterviewsList'
import PageHelmet from 'src/components/PageHelmet'
import Subscribe from '../components/Subscribe'
import withLayout from 'src/components/withLayout'
import { injectIntl } from 'react-intl'

class Factory extends Component {
  render() {
    const {
      data: { factory, subscribe },
      locale,
      changeLocale,
      intl,
    } = this.props

    return (
      <div>
        <PageHelmet title={intl.formatMessage({ id: 'home.interviewButton' })} locale={locale} defer={false} />
        <Header full={true} locale={locale} changeLocale={changeLocale} />
        <InterviewsList interviews={factory} locale={locale} />
        <Subscribe style={{ marginTop: '70px' }} locale={locale} subscribe={subscribe} />
        <Footer locale={locale} />
      </div>
    )
  }
}

export const query = graphql`
  query Factory($locale: String!) {
    factory: allDatoCmsFactoryRecord(
      filter: { locale: { eq: $locale }, slug: { ne: "_" } }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          title
          description
          podcast {
            podcastTitle
            podcastGuest
            podcastCover {
              id
              fluid(maxWidth: 400, imgixParams: { fm: "jpg", auto: "compress, format" }) {
                ...GatsbyDatoCmsFluid
              }
            }
            duration
            podcast {
              url
            }
          }
          image {
            url
            fluid(maxWidth: 1240, imgixParams: { fm: "jpg", auto: "compress, format" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          publishDate(formatString: "DD.MM.YYYY")
          slug
        }
      }
    }
    subscribe: file(relativePath: { eq: "subscribe-illustration.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const customProps = {
  localeKey: 'factory',
}

export default withLayout(customProps)(injectIntl(Factory))
